.App {
  text-align: center;
  caret-color: transparent;
}

input {
  caret-color: black;
}

.MuiLinearProgress-bar {
  /* Fixes delayed timer */
  transition: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}